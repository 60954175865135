/* eslint-disable global-require */
/* eslint-disable eol-last */

const appImageUrlPrefix = `${process.env.VUE_APP_API_BASE_END_POINT}/images`

export default [
  {
    title: 'Dashboard',
    route: 'shop-dashboard',
    image: require('@/assets/images/app-icons/Dashboard.png'),
  },
  {
    title: 'Analytics',
    route: 'shop-analytics',
    image: require('@/assets/images/app-icons/business-report.png'),
  },
  {
    title: 'Product',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Brands',
        route: 'shop-brand',
        image: `${appImageUrlPrefix}/icons/brand.png`,
      },
      {
        title: 'Category',
        route: 'shop-category',
        image: require('@/assets/images/app-icons/sub-menu/Category.png'),
      },
      {
        title: 'Items',
        route: 'shop-items',
        image: require('@/assets/images/app-icons/sub-menu/Items.png'),
      },
      {
        title: 'Add Ons',
        route: 'shop-addons',
        image: require('@/assets/images/app-icons/sub-menu/add on.png'),
      },
      {
        title: 'Variations',
        route: 'shop-variations',
        image: require('@/assets/images/app-icons/sub-menu/variation.png'),
      },
      {
        title: 'Print Barcode',
        route: 'shop-bracode-sheet',
        image: `${appImageUrlPrefix}/icons/barcode.png`,
      },
    ],
  },
  {
    title: 'Marketing',
    image: `${appImageUrlPrefix}/icons/marketing.png`,
    children: [
      {
        title: 'AI Images',
        route: 'shop-ai-images',
        image: require('@/assets/images/app-icons/ai/image-48.png'),
      },
      {
        title: 'AI Writer',
        route: 'shop-ai-writer',
        image: require('@/assets/images/app-icons/ai/template-48.png'),
      },
      {
        title: 'Promocodes',
        route: 'shop-promocodes',
        image: require('@/assets/images/app-icons/coupon.png'),
      },
    ],
  },
  {
    title: 'Accounting',
    image: `${appImageUrlPrefix}/icons/accounting.png`,
    children: [
      {
        title: 'Bank Accounts',
        route: 'shop-account-accounts',
        image: `${appImageUrlPrefix}/icons/bank_account.png`,
      },
      {
        title: 'Transfer Money',
        route: 'shop-account-transfer-money',
        image: `${appImageUrlPrefix}/icons/money-transfer.png`,
      },
      {
        title: 'Expenses',
        image: `${appImageUrlPrefix}/icons/expense.png`,
        children: [
          {
            title: 'Category',
            route: 'shop-account-expense-categories',
            image: `${appImageUrlPrefix}/icons/category.png`,
          },
          {
            title: 'List',
            route: 'shop-account-expenses',
            image: `${appImageUrlPrefix}/icons/list.png`,
          },
        ],
      },
      {
        title: 'Deposits',
        image: `${appImageUrlPrefix}/icons/deposit.png`,
        children: [
          {
            title: 'Category',
            route: 'shop-account-deposits-categories',
            image: `${appImageUrlPrefix}/icons/category.png`,
          },
          {
            title: 'List',
            route: 'shop-account-deposits',
            image: `${appImageUrlPrefix}/icons/list.png`,
          },
        ],
      },
    ],
  },
  {
    title: 'HRM',
    image: require('@/assets/images/app-images/icons8-hr-48.png'),
    children: [
      {
        title: 'Departments',
        route: 'shop-hrm-departments',
        image: require('@/assets/images/app-images/department.png'),
      },
      {
        title: 'Designation',
        route: 'shop-hrm-designation',
        image: require('@/assets/images/app-images/designation.png'),
      },
      {
        title: 'Shift',
        route: 'shop-hrm-shift',
        image: require('@/assets/images/app-images/office_shift.png'),
      },
      {
        title: 'Employees',
        route: 'shop-hrm-employees',
        image: require('@/assets/images/app-images/employee.png'),
      },
      {
        title: 'Attendance',
        route: 'shop-hrm-attendance',
        image: require('@/assets/images/app-images/attendance.png'),
      },
      {
        title: 'Holidays',
        route: 'shop-hrm-holidays',
        image: require('@/assets/images/app-images/holidays.png'),
      },
      {
        title: 'Leaves',
        image: require('@/assets/images/app-images/leave_request.png'),
        children: [
          {
            title: 'Leave Requests',
            route: 'shop-hrm-leaves',
            image: `${appImageUrlPrefix}/icons/leave_request.png`,
          },
          {
            title: 'Leave types',
            route: 'shop-hrm-leave-types',
            image: `${appImageUrlPrefix}/icons/leave_types.png`,
          },
        ],
      },
      {
        title: 'Payrolls',
        route: 'shop-hrm-payrolls',
        image: `${appImageUrlPrefix}/icons/payroll.png`,
      },
    ],
  },
  {
    title: 'Delivery Hub',
    image: require('@/assets/images/app-icons/Drive.png'),
    children: [
      {
        title: 'Live Tracking',
        route: 'shop-agents-tracking',
        image: `${appImageUrlPrefix}/icons/track.svg`,
      },
      {
        title: 'Delivery Settings',
        route: 'shop-delivery-settings',
        image: require('@/assets/images/app-icons/sub-menu/Delivery setting.png'),
      },
      {
        title: 'Agents List',
        route: 'shop-agents',
        image: `${appImageUrlPrefix}/icons/agent_list.png`,
      },
      {
        title: 'Agents Dashboard',
        route: 'shop-agents-dashboard',
        image: require('@/assets/images/app-icons/agent dashboard.png'),
      },
      {
        title: 'Agents Pricing',
        route: 'shop-agents-pricing',
        image: `${appImageUrlPrefix}/icons/agent_payout.svg`,
      },
      {
        title: 'Agents Earning',
        route: 'shop-agents-earning',
        image: `${appImageUrlPrefix}/icons/agent_earning.svg`,
      },
      {
        title: 'Agent Attendance',
        route: 'shop-agents-attendance',
        image: `${appImageUrlPrefix}/icons/attendance.svg`,
      },
    ],
  },
  {
    title: 'Bookings',
    route: 'shop-bookings',
    image: require('@/assets/images/app-icons/calendar.png'),
    hideInMenu: false,
  },
  // {
  //   title: 'Messages',
  //   route: 'shop-messaging',
  //   image: require('@/assets/images/app-icons/chat.png'),
  // },
  {
    title: 'Order History',
    route: 'order-history',
    image: require('@/assets/images/app-icons/exchange.png'),
  },
  // {
  //   title: 'Wallet Transaction',
  //   route: 'shop-wallet',
  //   image: require('@/assets/images/app-icons/wallet_new.png'),
  // },
  {
    title: 'Reports',
    image: require('@/assets/images/app-icons/history.png'),
    children: [
      {
        title: 'Order Report',
        route: 'order-statement',
        image: require('@/assets/images/app-icons/sub-menu/order statement.png'),
      },
      {
        title: 'Shop Report',
        route: 'shop-statement',
        image: require('@/assets/images/app-icons/sub-menu/shop statement.png'),
      },
    ],
  },
  {
    title: 'Utilities',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Overviews',
        route: 'shop-overviews',
        image: require('@/assets/images/app-icons/sub-menu/overview.png'),
      },
      {
        title: 'Photos',
        route: 'shop-photos',
        image: require('@/assets/images/app-icons/sub-menu/photos.png'),
      },
      {
        title: 'Feedback',
        route: 'shop-reviews',
        image: require('@/assets/images/app-icons/reviews.png'),
      },
      {
        title: 'Reviews',
        route: 'shop-user-reviews',
        image: require('@/assets/images/app-icons/sub-menu/reviews.png'),
      },
      {
        title: 'Policies',
        route: 'shop-policies',
        image: require('@/assets/images/app-icons/sub-menu/policies.png'),
      },
      {
        title: 'Documents',
        route: 'shop-documents',
        image: require('@/assets/images/app-images/My Documents.png'),
      },
    ],
  },
  {
    title: 'Settings',
    image: require('@/assets/images/app-icons/settings.png'),
    children: [
      {
        title: 'Profile',
        route: 'shop-profile',
        image: require('@/assets/images/app-icons/sub-menu/shop.png'),
      },
      {
        title: 'Payments Config',
        route: 'payment-config',
        image: require('@/assets/images/app-icons/sub-menu/payment_config.png'),
      },
      {
        title: 'Warehouse',
        route: 'shop-warehouse',
        image: `${appImageUrlPrefix}/icons/warehouse.png`,
      },
    ],
  },
  {
    title: 'Affiliate Program',
    image: `${appImageUrlPrefix}/icons/affilate.png`,
    children: [
      {
        title: 'Overview',
        route: 'shop-affiliation-overview',
        image: `${appImageUrlPrefix}/icons/overview.png`,
      },
      {
        title: 'Payout Configuration',
        route: 'shop-affiliation-payments',
        image: `${appImageUrlPrefix}/icons/payment_configuration.png`,
      },
      {
        title: 'Earning History',
        route: 'shop-affiliation-payouts',
        image: `${appImageUrlPrefix}/icons/earning_history.png`,
      },
      {
        title: 'Payment History',
        route: 'shop-affiliation-earnings',
        image: `${appImageUrlPrefix}/icons/payment_history.png`,
      },
    ],
  },
  {
    title: 'About',
    image: `${appImageUrlPrefix}/icons/about.svg`,
    children: [
      {
        title: 'Terms of Use',
        route: 'terms-and-condition',
        image: `${appImageUrlPrefix}/icons/terms-and-conditions.svg`,
      },
      {
        title: 'Privacy Policy',
        route: 'privacy-policy',
        image: `${appImageUrlPrefix}/icons/privacy-policy.svg`,
      },
    ],
  },
]
