/* eslint-disable global-require */
/* eslint-disable eol-last */

const appImageUrlPrefix = `${process.env.VUE_APP_API_BASE_END_POINT}/images`

export default [
  {
    title: 'Dashboard',
    route: 'provider-requests',
    image: require('@/assets/images/app-icons/Dashboard.png'),
  },
  {
    title: 'Analytics',
    route: 'provider-analytics',
    image: require('@/assets/images/app-icons/business-report.png'),
  },
  {
    title: 'Services',
    route: 'provider-my-services',
    image: require('@/assets/images/app-icons/sub-menu/my_services.png'),
  },
  {
    title: 'Marketing',
    image: `${appImageUrlPrefix}/icons/marketing.png`,
    children: [
      {
        title: 'AI Images',
        route: 'provider-ai-images',
        image: require('@/assets/images/app-icons/ai/image-48.png'),
      },
      {
        title: 'AI Writer',
        route: 'provider-ai-writer',
        image: require('@/assets/images/app-icons/ai/template-48.png'),
      },
      {
        title: 'Promocodes',
        route: 'provider-promocodes',
        image: require('@/assets/images/app-icons/coupon.png'),
      },
    ]
  },
  {
    title: 'Accounting',
    image: `${appImageUrlPrefix}/icons/accounting.png`,
    children: [
      {
        title: 'Bank Accounts',
        route: 'provider-account-accounts',
        image: `${appImageUrlPrefix}/icons/bank_account.png`,
      },
      {
        title: 'Transfer Money',
        route: 'provider-account-transfer-money',
        image: `${appImageUrlPrefix}/icons/money-transfer.png`,
      },
      {
        title: 'Expenses',
        image: `${appImageUrlPrefix}/icons/expense.png`,
        children: [
          {
            title: 'Category',
            route: 'provider-account-expense-categories',
            image: `${appImageUrlPrefix}/icons/category.png`,
          },
          {
            title: 'List',
            route: 'provider-account-expenses',
            image: `${appImageUrlPrefix}/icons/list.png`,
          },
        ]
      },
      {
        title: 'Deposits',
        image: `${appImageUrlPrefix}/icons/deposit.png`,
        children: [
          {
            title: 'Category',
            route: 'provider-account-deposits-categories',
            image: `${appImageUrlPrefix}/icons/category.png`,
          },
          {
            title: 'List',
            route: 'provider-account-deposits',
            image: `${appImageUrlPrefix}/icons/list.png`,
          },
        ]
      },
    ],
  },
  {
    title: 'HRM',
    image: require('@/assets/images/app-images/icons8-hr-48.png'),
    children: [
      {
        title: 'Departments',
        route: 'provider-hrm-departments',
        image: require('@/assets/images/app-images/department.png'),
      },
      {
        title: 'Designation',
        route: 'provider-hrm-designation',
        image: require('@/assets/images/app-images/designation.png'),
      },
      {
        title: 'Shift',
        route: 'provider-hrm-shift',
        image: require('@/assets/images/app-images/office_shift.png'),
      },
      {
        title: 'Employees',
        route: 'provider-hrm-employees',
        image: require('@/assets/images/app-images/employee.png'),
      },
      {
        title: 'Attendance',
        route: 'provider-hrm-attendance',
        image: require('@/assets/images/app-images/attendance.png'),
      },
      {
        title: 'Holidays',
        route: 'provider-hrm-holidays',
        image: require('@/assets/images/app-images/holidays.png'),
      },
      {
        title: 'Leaves',
        image: require('@/assets/images/app-images/leave_request.png'),
        children: [
          {
            title: 'Leave Requests',
            route: 'provider-hrm-leaves',
            image: require('@/assets/images/app-images/leave_request.png'),
          },
          {
            title: 'Leave types',
            route: 'provider-hrm-leave-types',
            image: `${appImageUrlPrefix}/icons/leave_types.png`,
          },
        ]
      },
      {
        title: 'Payrolls',
        route: 'provider-hrm-payrolls',
        image: `${appImageUrlPrefix}/icons/payroll.png`,
      },
    ],
  },
  {
    title: 'History',
    image: require('@/assets/images/app-icons/1history.png'),
    children: [
      {
        title: 'Order',
        route: 'provider-order',
        image: require('@/assets/images/app-icons/sub-menu/order.png'),
      },
      {
        title: 'Services',
        route: 'provider-services',
        image: require('@/assets/images/app-icons/sub-menu/Service.png'),
      },
    ],
  },
  {
    title: 'Agents',
    route: 'provider-agents',
    image: require('@/assets/images/app-icons/agent.png'),
  },
  {
    title: 'Bookings',
    route: 'provider-bookings',
    image: require('@/assets/images/app-icons/calendar.png'),
  },
  {
    title: 'Utilities',
    image: require('@/assets/images/app-icons/checklist.png'),
    children: [
      {
        title: 'Overviews',
        route: 'provider-overviews',
        image: require('@/assets/images/app-icons/sub-menu/overview.png'),
      },
      {
        title: 'Photos',
        route: 'provider-photos',
        image: require('@/assets/images/app-icons/sub-menu/photos.png'),
      },
      {
        title: 'Reviews',
        route: 'provider-reviews',
        image: require('@/assets/images/app-icons/sub-menu/reviews.png'),
      },
      {
        title: 'Policies',
        route: 'provider-policies',
        image: require('@/assets/images/app-icons/sub-menu/policies.png'),
      },
      {
        title: 'Documents',
        route: 'provider-documents',
        image: require('@/assets/images/app-images/My Documents.png'),
      },
    ],
  },
  {
    title: 'Settings',
    image: require('@/assets/images/app-icons/settings.png'),
    children: [
      // {
      //   title: 'Account',
      //   hideInMenu: !window.SuperApp.walletEnabled,
      //   route: 'provider-wallet',
      //   image: require('@/assets/images/app-icons/sub-menu/account.png'),
      // },
      {
        title: 'Profile',
        route: 'provider-profile',
        image: require('@/assets/images/app-icons/sub-menu/profile.png'),
      },
      {
        title: 'Payments Config',
        route: 'provider-payment-config',
        image: require('@/assets/images/app-icons/sub-menu/payment_config.png'),
      },
    ],
  },
  {
    title: 'Affiliate Program',
    image: `${appImageUrlPrefix}/icons/affilate.png`,
    children: [
      {
        title: 'Overview',
        route: 'provider-affiliation-overview',
        image: `${appImageUrlPrefix}/icons/overview.png`,
      },
      {
        title: 'Payout Configuration',
        route: 'provider-affiliation-payments',
        image: `${appImageUrlPrefix}/icons/payment_configuration.png`,
      },
      {
        title: 'Earning History',
        route: 'provider-affiliation-payouts',
        image: `${appImageUrlPrefix}/icons/earning_history.png`,
      },
      {
        title: 'Payment History',
        route: 'provider-affiliation-earnings',
        image: `${appImageUrlPrefix}/icons/payment_history.png`,
      },
    ],
  },
  {
    title: 'About',
    image: `${appImageUrlPrefix}/icons/about.svg`,
    children: [
      {
        title: 'Terms of Use',
        route: 'terms-and-condition',
        image: `${appImageUrlPrefix}/icons/terms-and-conditions.svg`,
      },
      {
        title: 'Privacy Policy',
        route: 'privacy-policy',
        image: `${appImageUrlPrefix}/icons/privacy-policy.svg`,
      },
    ],
  },
]
